<template>
  <b-row>
    <b-col>
      <b-container>
        <div class="mt-2 d-flex flex-column">
          <input type="file" class="mb-1" @change="handleFileChange" />
          <textarea v-model="result" rows="20"></textarea>
        </div>
      </b-container>
    </b-col>
  </b-row>
</template>

<script>
import config from "@/configs";
import axios from "axios";
import { BRow, BCol, BContainer } from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BContainer,
  },
  data() {
    return {
      result: "",
      token: "",
    };
  },
  created()
  {
    const urlParams = new URLSearchParams(window.location.search);
    this.token = urlParams.get("token");
  },
  methods: {
    async handleFileChange(event) {
      try {
        let file = event.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("token", this.token);

        const apiEndpoint = config.api.hostname;

        const response = await axios.post(
          apiEndpoint + "/pdf-parsing-test",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        this.result = response.data;
      } catch {
        alert("Error!");
      }
    },
  },
};
</script>